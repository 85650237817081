import { Project, UpdateProjectData } from "../../../../models/Project";
import { Box, Switch, useTheme } from "@mui/material";
import React, { useState } from "react";
import { SettingsSwitchOptionWrapper } from "../../../../components/form/OptionsWrapper";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import { FormButtons } from "../../../../components/form/FormButtons";

interface Props {
  project: Project
  submit: (data: UpdateProjectData) => Promise<void>
}

interface State {
  isLoading: boolean
  showAccountExistenceHints: boolean
  onlyShowActualLoginMethods: boolean
}

export const Privacy = (props: Props) => {
  const theme = useTheme()
  const { project, submit } = props
  const [state, setState] = useState<State>({
    isLoading: false,
    showAccountExistenceHints: project.privacy?.show_account_existence_hints ?? false,
    onlyShowActualLoginMethods: project.privacy?.only_show_actual_login_methods ?? false
  })

  const somethingChanged = () => {
    return (project.privacy?.show_account_existence_hints ?? false) !== state.showAccountExistenceHints ||
      (project.privacy?.only_show_actual_login_methods ?? false) !== state.onlyShowActualLoginMethods
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState({ ...state, isLoading: true })
    await submit({
      privacy: {
        show_account_existence_hints: state.showAccountExistenceHints,
        only_show_actual_login_methods: state.onlyShowActualLoginMethods,
      }
    })
    setState({ ...state, isLoading: false })
  }

  const onCancel = () => {
    setState({
      ...state,
      showAccountExistenceHints: project.privacy?.show_account_existence_hints ?? false,
      onlyShowActualLoginMethods: project.privacy?.only_show_actual_login_methods ?? false
    })
  }

  return <Box>
    <form onSubmit={onSubmit}>
      <SettingsSwitchOptionWrapper sx={{
        borderBottom: `1px solid ${theme.palette.grey["900"]}`
      }}>
        <SettingsHead title="Privacy"
                      description="Control whether the disclosure of information about user accounts should be accepted for the sake of usability."
                      sx={{ mb: 1 }} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead title="Show account existence hints"
                      description="When registering or trying to log in, feedback is displayed indicating whether or not an email address already exists (e.g. “The email is already taken.” or “The email address is unknown.”). When disabled, the system hides this information and behaves identically in both cases to avoid disclosing information about existing email addresses."
                      helpText="Full protection against email enumeration attacks is only provided if both settings, “Show account existence hints” and “Hide unavailable login methods” are disabled. Please note that this may result in a poorer user experience, as users will no longer receive direct feedback if, for example, they have entered an incorrect email address, or if they are suggested the option to enter a password even though they do not have one."
                      size="small" minElementsVersion="1.5" />
        <Switch name="showAccountExistenceHints" color="success" checked={state.showAccountExistenceHints}
                onChange={onChange} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead title="Hide unavailable login methods"
                      description="Only display the authentication methods that are actually available to a user during the sign-in process (e.g. after the user enters an e-mail address as the identifier in the sign-in form, only prompt the user for a password if they actually have one). If disabled, all theoretically possible authentication options are always displayed to avoid disclosing information about existing email addresses."
                      helpText="Full protection against email enumeration attacks is only provided if both settings, “Show account existence hints” and “Hide unavailable login methods” are disabled. Please note that this may result in a poorer user experience, as users will no longer receive direct feedback if, for example, they have entered an incorrect email address, or if they are suggested the option to enter a password even though they do not have one."
                      size="small" />
        <Switch name="onlyShowActualLoginMethods" color="success" checked={state.onlyShowActualLoginMethods}
                onChange={onChange} />
      </SettingsSwitchOptionWrapper>
      <FormButtons onCancel={onCancel} loading={state.isLoading} disabled={!somethingChanged()} />
    </form>
  </Box>
}