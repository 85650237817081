import { createTheme, outlinedInputClasses } from "@mui/material";
import "./ToolbarOverwrite"

const hankoTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#AEDFFF',
    },
    error: {
      main: '#FF2E4C',
    },
    warning: {
      main: '#E09323',
    },
    success: {
      main: '#2EC875',
    },
    grey: {
      900: '#262B35',
    },
    background: {
      paper: '#262B35'
    }
  },
  typography: {
    fontFamily: [
      'IBM Plex Sans',
      '-apple-system',
      'sans-serif'
    ].join(','),
    h4: {
      fontWeight: 700,
    },
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        filledError: {
          backgroundColor: '#FF2E4C'
        },
        filledSuccess: {
          backgroundColor: '#2EC875'
        },
        outlinedWarning: {
          borderColor: '#E09323',
          backgroundColor: '#18150F',
          color: '#FFE2B7'
        },
        outlinedInfo: {
          borderColor: '#AEDFFF',
          backgroundColor: '#151A1C',
          color: '#FFFFFF'
        },
        outlinedError: {
          borderColor: '#FF2E4C',
          backgroundColor: '#1A0F12',
          color: '#F4C7C7'
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          height: '40px'
        }
      }
    },
    MuiToolbar: {
      variants: [
        {
          props: { variant: 'large' },
          style: {
            minHeight: '84px'
          }
        }
      ]
    },
    MuiChip: {
      styleOverrides: {
        sizeMedium: {
          height: "30px",
          fontSize: "0.8125rem",
          fontWeight: 500
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`&:hover:not(.Mui-disabled):not(.Mui-error) .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#7C8E9C'
          }
        }
      }
    }
  }
});


export default hankoTheme;
