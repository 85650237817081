import { Project, UpdateProjectData } from "../../../../models/Project";
import React, { useState } from "react";
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme
} from "@mui/material";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import { SettingsOptionWrapper, SettingsSwitchOptionWrapper } from "../../../../components/form/OptionsWrapper";
import { FormButtons } from "../../../../components/form/FormButtons";

interface Props {
  project: Project
  submit: (data: UpdateProjectData) => Promise<void>
}

interface State {
  isLoading: boolean
  device_trust_policy: "always" | "prompt" | "never"
  device_trust_duration: string
}

export const DeviceTrust = (props: Props) => {
  const theme = useTheme()
  const { project, submit } = props
  const [state, setState] = useState<State>({
    isLoading: false,
    ...project.mfa
  })

  const somethingChanged = () => {
    return project.mfa.device_trust_policy !== state.device_trust_policy ||
      project.mfa.device_trust_duration !== state.device_trust_duration
  }

  const onSelectChange = (e: SelectChangeEvent) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState({ ...state, isLoading: true })
    await submit({
      mfa: {
        ...project.mfa,
        device_trust_duration: state.device_trust_duration,
        device_trust_policy: state.device_trust_policy,
      }
    })
    setState({ ...state, isLoading: false })
  }

  const onCancel = () => {
    setState({
      ...state,
      ...project.mfa
    })
  }

  return <Box>
    <SettingsSwitchOptionWrapper
      sx={{
        borderBottom: `1px solid ${theme.palette.grey["900"]}`
      }}>
      <SettingsHead description="Allow users to skip subsequent 2FA prompts on trusted devices."
                    title="Device trust" minElementsVersion="1.3" />
    </SettingsSwitchOptionWrapper>
    <form onSubmit={onSubmit}>
      {project.custom_domain?.is_active === true || state.device_trust_policy === "never" ? null :
        <Alert variant="outlined" severity="warning" sx={{ mb: 2 }}>
          <Typography variant="h6" fontSize="0.8125rem">Custom domain recommended</Typography>
          <Typography variant="body2">The device trust cookie can only be stored and accessed reliably if a <Link
            color="inherit" href="./custom_domain">custom domain</Link> is configured for this project.</Typography>
        </Alert>
      }
      <SettingsOptionWrapper>
        <SettingsHead title="Device trust policy"
                      description="Set the device trust policy for all users."
                      helpText="'Always' means that a device is automatically trusted after the first successful 2FA for the duration set below. 'Prompt' means the user will be asked if the current device shall be trusted. 'Never' disables device trust, i.e. the user will always be prompted for a second factor."
                      size="small"
                      sx={{ mb: 0 }} minElementsVersion="1.3" />
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: '220px' } }} size="small">
          <InputLabel id="device_trust_policy">Device trust policy</InputLabel>
          <Select labelId="device_trust_policy" id="device_trust_policy_select"
                  name="device_trust_policy" label="Device trust policy" onChange={onSelectChange}
                  value={state.device_trust_policy}>
            <MenuItem key="always" value="always">Always</MenuItem>
            <MenuItem key="prompt" value="prompt">Prompt</MenuItem>
            <MenuItem key="never" value="never">Never</MenuItem>
          </Select>
        </FormControl>
      </SettingsOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead
          description="The duration after a successful 2FA for which device trust is considered to be established. After this, the user must perform 2FA again the next time they log in."
          title="Device trust duration" minElementsVersion="1.3" size="small" />
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: '220px' } }} size="small">
          <InputLabel id="device_trust_duration">Device trust duration</InputLabel>
          <Select labelId="device_trust_duration" id="device_trust_duration_select"
                  name="device_trust_duration" label="Device trust duration" onChange={onSelectChange}
                  value={state.device_trust_duration}>
            <MenuItem key="24h" value="24h">24 hours</MenuItem>
            <MenuItem key="168h" value="168h">7 days</MenuItem>
            <MenuItem key="720h" value="720h">30 days</MenuItem>
            <MenuItem key="1440h" value="1440h">60 days</MenuItem>
            <MenuItem key="2160h" value="2160h">90 days</MenuItem>
          </Select>
        </FormControl>
      </SettingsOptionWrapper>

      <FormButtons onCancel={onCancel} loading={state.isLoading} disabled={!somethingChanged()} />
    </form>
  </Box>
}