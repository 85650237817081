import { useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../ProjectWrapper";
import React, { useContext, useState } from "react";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { SettingsHead } from "../../../components/form/SettingsHead";
import {
  Alert,
  AlertProps,
  Box,
  Chip,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { FormButtons } from "../../../components/form/FormButtons";
import { ApiClient } from "../../../client/ApiClient";
import { Project, ThirdPartyProvider, ThirdPartyProviders } from "../../../models/Project";
import { Organization } from "../../../models/Organization";
import { CheckCircleOutlined, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import GoogleIcon from '../../../assets/google.svg'
import GithubIcon from '../../../assets/github.svg'
import AppleIcon from '../../../assets/apple.svg'
import DiscordIcon from '../../../assets/discord.svg'
import MicrosoftIcon from '../../../assets/microsoft.svg'
import FacebookIcon from '../../../assets/facebook.svg'
import CopyableTextField from "../../../components/CopyableTextField";
import { HankoHelpTooltip } from "../../../components/Tooltip/Tooltip";
import LinkedinIcon from '../../../assets/linkedin.svg'


export const AuthProvider = () => {
  const { project, organization } = useOutletContext() as ContextType

  let providerRows: React.ReactNode[] = []
  let key: keyof ThirdPartyProviders
  for (key in project.third_party.providers) {
    providerRows.push(<ProviderSettingTableRow name={key} project={project} organization={organization}
                                               provider={(project.third_party.providers[key] as ThirdPartyProvider)}
                                               key={key} />)
  }

  return <Box maxWidth="800px" display="flex" flexDirection="column">
    <SettingsHead title="Social connections"
                  description='Configure social connections using OAuth. Active connections will show up as "Sign in with..." buttons during login and registration.' />
    <CopyableTextField copyableContent={project.api_url + "/thirdparty/callback"} label="Redirect URL (read-only)" />
    {project.custom_domain?.is_active === true ? null :
      <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
        <Typography variant="h6" fontSize="0.8125rem">Custom domain recommended</Typography>
        <Typography variant="body2">For Social SSO connections, a <Link color="inherit" href="./custom_domain">custom
          domain</Link> should be configured so that the login or consent screen at the SSO provider displays your
          domain instead of a hanko domain.</Typography>
      </Alert>
    }
    <Table sx={{ mt: 5 }}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={3}>
            <SettingsHead title="Connections" description={null} sx={{ mb: 0, gap: 0 }} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {providerRows}
      </TableBody>
    </Table>
  </Box>
}

const ProviderSettingTableRow = (props: Props) => {
  const [open, setOpen] = useState(false)
  return <>
    <TableRow hover sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }} onClick={() => setOpen(!open)}>
      <TableCell sx={{ width: '100%' }}><Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}><img style={{ width: '17px', height: '17px' }} src={providerCustomization[props.name].icon}
              alt="provider icon" /> {providerCustomization[props.name].name}</Box></TableCell>
      <TableCell sx={{ width: 0 }}>{props.provider.enabled ?
        <Chip label="Enabled" color="success" variant="outlined" icon={<CheckCircleOutlined color="success" />}
              sx={{ fontWeight: 500 }} /> :
        <Chip label="Disabled" color="primary" variant="outlined" sx={{ width: '100%', fontWeight: 500 }} />}
      </TableCell>
      <TableCell sx={{ width: 0 }}>
        <IconButton aria-label="expand row"
                    size="small"
                    disableRipple>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={{ py: 0 }} colSpan={3}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ProviderSetting {...props} />
        </Collapse>
      </TableCell>
    </TableRow>
  </>
}

interface Props {
  name: keyof ThirdPartyProviders
  provider: ThirdPartyProvider
  organization: Organization
  project: Project
}

interface ProviderSettingState {
  enabled: boolean
  client_id: string
  client_secret: string
  allow_linking: boolean
}

const ProviderSetting = (props: Props) => {
  const { name, provider, organization, project } = props
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState<ProviderSettingState>({
    enabled: provider.enabled ?? false,
    client_id: provider.client_id ?? "",
    client_secret: provider.secret ?? "",
    allow_linking: provider.allow_linking ?? false,
  })
  const { error, success } = useContext(SnackbarContext)
  const revalidator = useRevalidator()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked
    })
  }

  const onCancel = () => {
    setState({
      ...state,
      enabled: provider.enabled ?? false,
      client_id: provider.client_id ?? "",
      client_secret: provider.secret ?? "",
      allow_linking: provider.allow_linking ?? false,
    })
  }

  const somethingChanged = () => {
    return (provider.enabled ?? false) !== state.enabled ||
      (provider.client_id ?? "") !== state.client_id ||
      (provider.secret ?? "") !== state.client_secret ||
      (provider.allow_linking ?? false) !== state.allow_linking
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setLoading(true)
    try {
      const p = await ApiClient.updateProject(organization.id, project.id, {
        third_party: {
          ...project.third_party,
          providers: {
            ...project.third_party.providers,
            [name]: {
              enabled: state.enabled,
              client_id: state.client_id,
              secret: state.client_secret,
              allow_linking: state.allow_linking
            }
          }
        }
      })
      revalidator.revalidate()
      success("Updated settings successful")
      setState({
        ...state,
        client_secret: p.third_party.providers[name].secret ?? ""
      })
    } catch (e) {
      console.log(e)
      error("Failed to update settings")
    }
    setLoading(false)
  }

  return <Box sx={{
    mx: 0.5
  }}>
    <form onSubmit={onSubmit}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
        <FormGroup>
          <FormControlLabel
            control={<Switch onChange={onChecked} checked={state.enabled} color="success" name="enabled" />}
            label="Enable provider" name="enabled" />
        </FormGroup>
        <TextField name="client_id" label={providerCustomization[name].clientIdLabel ?? "Client ID"} fullWidth
                   type="text" onChange={onChange} value={state.client_id}
                   required={state.enabled} autoComplete="off" />
        <TextField name="client_secret" label={providerCustomization[name].clientSecretLabel ?? "Client secret"}
                   fullWidth type="text" onChange={onChange}
                   value={state.client_secret} required={state.enabled} autoComplete="off" />
        <FormGroup>
          <FormControlLabel control={<Switch onChange={onChecked} checked={state.allow_linking} color="success"
                                             name="allow_linking" />}
                            label={<Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, alignItems: 'center' }}>Allow
                              auto-linking <HankoHelpTooltip
                                title="This enables auto-linking of identities with the same email address, e.g. the ability to use Sign in with Google for an existing account that was created using the same email address. Enabling this setting may impose a security risk depending on the security of the 3rd-party identity provider." /></Box>}
                            name="allow_linking" />
        </FormGroup>
        <Typography>Need help? Check out the <Link target="_blank"
                                                   href={providerCustomization[props.name].documentationLink}>setup
          guide</Link>.</Typography>
        {providerCustomization[name].info === undefined ? null :
          <Alert variant="outlined"
                 severity={providerCustomization[name].info!!.severity}>{providerCustomization[name].info!!.text}</Alert>
        }
      </Box>
      <FormButtons onCancel={onCancel} loading={loading} disabled={!somethingChanged()} sx={{ my: 5 }} />
    </form>
  </Box>
}

type ThirdPartyCustomization = {
  name: string
  icon: string
  documentationLink: string
  clientIdLabel?: string
  clientSecretLabel?: string
  info?: {
    severity: AlertProps['severity'],
    text: string
  }
}

export const providerCustomization: Record<keyof ThirdPartyProviders, ThirdPartyCustomization> = {
  'google': {
    name: 'Google',
    icon: GoogleIcon,
    documentationLink: "https://docs.hanko.io/guides/authentication-methods/oauth/google",
  },
  'github': {
    name: 'GitHub',
    icon: GithubIcon,
    documentationLink: "https://docs.hanko.io/guides/authentication-methods/oauth/github",
  },
  'apple': {
    name: 'Apple',
    icon: AppleIcon,
    documentationLink: "https://docs.hanko.io/guides/authentication-methods/oauth/apple",
    clientIdLabel: "Services ID",
    clientSecretLabel: "Secret key",
    info: {
      severity: "warning",
      text: "Apple secrets expire after 6 months - You need to generate a new secret before the expiration date, otherwise your users using Apple Login will no longer be able to log back in."
    }
  },
  'discord': {
    name: 'Discord',
    icon: DiscordIcon,
    documentationLink: "https://docs.hanko.io/guides/authentication-methods/oauth/discord"
  },
  'microsoft': {
    name: 'Microsoft',
    icon: MicrosoftIcon,
    documentationLink: "https://docs.hanko.io/guides/authentication-methods/oauth/microsoft"
  },
  'linkedin': {
    name: 'LinkedIn',
    icon: LinkedinIcon,
    documentationLink: "https://docs.hanko.io/guides/authentication-methods/oauth/linkedin"
  },
  'facebook': {
    name: 'Facebook',
    icon: FacebookIcon,
    documentationLink: "https://docs.hanko.io/guides/authentication-methods/oauth/facebook"
  }
}
